import React from "react";
import "./frontpage.scss";
import Layout from "../components/layout/layout";
import LatestPosts from "../components/blocks/latest-posts";
import HeaderImage from "../components/header/header-image";
import Button from "../components/buttons/button";
import ArrowRight from "../assets/svg/icons-feather/arrow-right.svg";
import Services from "../components/blocks/services";
import {StaticImage} from "gatsby-plugin-image";

const EnglishFrontpage = () => {

  return (
    <Layout frontpage={true}>

      <main>
        <section>

          <HeaderImage/>

          <div className="image-text">
            <div className="inner-content">
              <div className="text">
                <p>I'm no exception when it comes to these <span className="pop">challenges</span>. For years, I felt I wasn't using my <span className="pop">full potential</span> and I didn't really feel heard.</p>
                <p>Until I decided to <span className="pop">change</span> that. I happily share my knowledge and experience with you.</p>
                <Button link="/en/about"><ArrowRight width="32" height="32"/>About me</Button>
              </div>

              <div className="image">
                <StaticImage
                  alt="Karolien Koolhof"
                  src="../assets/images/karolien-presenteert-3.jpg"
                  placeholder="blurred"
                  layout="constrained"
              />
            </div>
            </div>
          </div>

          {/* 3 blokjes services */}
          <Services showTitle={true} padding={true}/>

          {/* 3 blokjes blog */}
          <LatestPosts/>

        </section>
      </main>
      
    </Layout>
  )
};

export default EnglishFrontpage;
